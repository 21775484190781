/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import logo from "../assets/images/endless-logo.png";
import man from "../assets/images/user/1.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Login, LOGIN, YourName, Password, RememberMe, session_expired } from "../constant";
import { useNavigate, useLocation } from "react-router-dom";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const location = useLocation();


  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));

  useEffect(() => {
    if (value !== null) localStorage.setItem("profileURL", value);
    else localStorage.setItem("profileURL", man);
  }, [value]);

  const loginAuth = async (e) => {
    
    fetch(`${process.env.REACT_APP_BACKEND_URL}auth/login`,
      {
        method:"POST",
        body:JSON.stringify({
          usernameOrEmail:email,
          password:password
        }),
        headers:{
          "Content-Type":"application/json"
        }
      }
    )
    .then( response => response.ok? response.json():Promise.reject(response))
    .then( user => {
      localStorage.setItem("login", true);
      localStorage.setItem("userId", user.id);
      localStorage.setItem("userToken", user.token);
      localStorage.setItem("username", user.username);
      localStorage.setItem("userRoles", JSON.stringify(user.roles));
      if(user.type != "DRIVER")
        navigate(`${process.env.PUBLIC_URL}/orders`);
      else
      navigate(`${process.env.PUBLIC_URL}/orders/driver`);

    })
    .catch(error => toast.error(error))

  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    console.log(searchParams.get('sessionexpired'))
    if(searchParams.get('sessionexpired'))
      toast.error(session_expired)
  }, [location]);

  

  return (
    <div>
      <div className="page-wrapper">
        <div className="container-fluid p-0">
          {/* <!-- login page start--> */}
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-12">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <div className="text-center">
                      <img src={logo} style={{width:30+'vw'}}alt="" />
                    </div>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="text-center">
                          <h4>{LOGIN}</h4>
                          <h6>{"Enter your Username and Password"} </h6>
                        </div>
                        <form className="theme-form">
                          <div className="form-group">
                            <label className="col-form-label pt-0">{YourName}</label>
                            <input className="form-control" type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email address" />
                          </div>
                          <div className="form-group">
                            <label className="col-form-label">{Password}</label>
                            <input className="form-control" type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                          </div>
                          <div className="checkbox p-0">
                            <input id="checkbox1" type="checkbox" />
                            <label htmlFor="checkbox1">{RememberMe}</label>
                          </div>
                          <div className="form-group form-row mt-3 mb-0 d-grid">
                            <button className="btn btn-primary" type="button" onClick={() => loginAuth()}>
                              {Login}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          {/* <!-- login page end--> */}
        </div>
      </div>
    </div>
  );
};

export default Signin;
