import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
//import { statesData } from './statesdata.js'
import { ToastContainer, toast } from "react-toastify";
import DataTable from 'react-data-table-component'
import useCrud from '../../hooks/use_crud';
import "react-toastify/dist/ReactToastify.css";
import "./addresses_view.css";
import LoadingSpinner from '../../components/my-components/loading_spinner';
import { user_name_filter_text, address_colony_filter_text, address_municipality_filter_text, address_privated_filter_text, address_title_text, address_type_filter_text, dropdown_default_text, filters_text } from '../../constant';
import Breadcrumb from '../../components/common/breadcrumb';


const AddressesView = () =>{

    const [loading,setLoading] = useState(false)
    const {list, find, totalElements} = useCrud({url: "address", setLoading: setLoading, firstLoad:false})
    const {list: states, create: createState } = useCrud({url:"states",  setLoading: setLoading, firstLoad: true})
    const {list: municipalities, create: createMunicipality, find: findMunicipalities} = useCrud({url:"municipality",  setLoading: setLoading, firstLoad: true})
    const {list: colonies, create: createColony, find: findColony} = useCrud({url:"colony",  setLoading: setLoading, firstLoad: false})
    const {list: addressTypes} = useCrud({url:"address-type",  setLoading: setLoading, firstLoad: true})
    const [selectedState, setSelectedState] = useState({
        id:-1,
        name: "States"
    });
    const [selectedMunicipality, setSelectedMunicipality] = useState({
        id:-1,
        name: "Municipalities"
    });
    const [selectedColony, setSelectedColony] = useState({
        id:-1,
        name: "Colony"
    });
    const [selectedAddressType, setSelectedAddressType] = useState({
        id:-1,
        name: "AddressType"
    });
    const [selectedUsername, setSelectedUsername] = useState('');
    const [selectedPrivate, setSelectedPrivate] = useState(undefined);
    const [filters, setFilters] = useState({});
   


    
    

    useEffect(()=>{
        find(filters)
    },[filters])

    useEffect(()=>{

        setSelectedMunicipality({
            id:-1,
            name: "Ninguno"
        })

        findMunicipalities({
            state:{id:parseInt(selectedState.id)}
        },
        {
            page:0,
            size:50
        }
        )
    },[selectedState])

    useEffect(()=>{

        setSelectedColony({
            id:-1,
            name: "Ninguno"
        })

        findColony({
            municipality:{id:parseInt(selectedMunicipality.id)}
        },
        {
            page:0,
            size:50
        }
        )
    },[selectedMunicipality])
    
   
    useEffect(()=>{
        let filtersAux = {}

        if(selectedAddressType.id!=-1)
            filtersAux = {
                ...filtersAux,
                addressType:{id:parseInt(selectedAddressType.id)}
            }

        if(selectedColony.id!=-1)
            filtersAux = {
                ...filtersAux,
                colony:{id:parseInt(selectedColony.id)}
            }
        else if(selectedMunicipality.id!=-1)
            filtersAux = {
                ...filtersAux,
                colony:{municipality:{id:parseInt(selectedMunicipality.id)}}
            }
        else if(selectedState.id!=-1)
            filtersAux = {
                ...filtersAux,
                colony:{municipality:{state:{id:parseInt(selectedState.id)}}}
            }
        

        if(selectedPrivate!=undefined)
            filtersAux = {
                ...filtersAux,
                privated: selectedPrivate
            }

        if(selectedUsername!='')
            filtersAux = {
                ...filtersAux,
                users: {username: selectedUsername}
            }

        
        setFilters(filtersAux)
    },[selectedAddressType, selectedColony, selectedPrivate, selectedUsername])

    const columns = [
      {name: '#',selector: (row) =>row.id,sortable: true,center:true,},
      {name: 'Alias',selector: (row) =>row.alias,sortable: true,center:true,},
      {name: 'Referencias',minWidth: '200px',selector: (row) =>row.refernce,sortable: true,center:true,},
      {name: 'Colonia',selector: (row) =>row.colony?row.colony.name:"",sortable: true,center:true,},
      {name: 'Municipio',minWidth: '200px',selector: (row) =>row.colony && row.colony.municipality?row.colony.municipality.name:"",sortable: true,center:true,},
      {name: 'Estado',minWidth: '200px',selector: (row) =>row.colony && row.colony.municipality && row.colony.municipality.state?row.colony.municipality.state.name:"",sortable: true,center:true,},
      {name: 'Tipo de dirección',minWidth: '200px',selector: (row) => row.addressType?row.addressType.name:"",sortable: true,center:true,}
  ];


    const handlePageChange = page => {
        find(filters,{size:10,page:page-1});
      };


    const handlePerRowsChange = async (newPerPage, page) => {
        find(filters,{size:newPerPage,page:page});

      };
    
    const uploadStateData = async () => {
        
       /* for(let i = 0; i< statesData.length; i++){
            const state = { name: statesData[i].nombre}
            const createdState = await createState(state)
            for(let e = 0; e < statesData[i].municipios.length; e++){
                const municipality = {name: statesData[i].municipios[e].nombre, state: createdState }
                const createdMunicipality = await createMunicipality(municipality)
                for( let a = 0; a < statesData[i].municipios[e].localidades.length; a++){
                    const colony = {name: statesData[i].municipios[e].localidades[a].nombre, municipality: createdMunicipality}
                    const createColonyResponse = await createColony(colony)
                    console.log('done')
                }
            }
            console.log('Estado Completado')
        }

        console.log('termineee')*/
    };

    return (
        <div>
            <Breadcrumb title={address_title_text} />
            <br/>
            <div className="row">
                <div className="col">
                    <label>{address_municipality_filter_text}</label>
                    <select className="form-control digits" id="exampleFormControlSelect23" onChange={(evt)=>{setSelectedState({id: +evt.target.value})}}>
                        <option value={-1}>{dropdown_default_text}</option>
                        {
                        states.map(state => <option value={state.id}>{state.name}</option>)
                        }
                    </select>
                </div>
                <div className="col">
                    <label>{address_municipality_filter_text}</label>
                    <select className="form-control digits" disabled={selectedState.id==-1} id="exampleFormControlSelect23" onChange={(evt)=>{setSelectedMunicipality({id: evt.target.value})}}>
                        <option value={-1}>{dropdown_default_text}</option>
                        {
                           municipalities.map(municipality => <option value={municipality.id}>{municipality.name}</option>)
                        }
                    </select>
                </div>
                <div className="col">
                    <label>{address_colony_filter_text}</label>
                    <select className="form-control digits" disabled={selectedMunicipality.id==-1} value={selectedColony.id} onChange={(evt)=>{setSelectedColony({id: evt.target.value})}}>
                        <option value={-1}>{"Ninguno"}</option>
                        {
                           colonies.map(colony => <option value={colony.id}>{colony.name}</option>)
                        }       
                    </select>
                </div>
                <div className="col">
                    <label>{address_type_filter_text}</label>
                    <select className="form-control digits" id="exampleFormControlSelect234" onChange={(evt)=>{setSelectedAddressType({id: evt.target.value})}}>
                        <option value={-1}>{"Ninguno"}</option>
                        {
                           addressTypes.map(addressType => <option value={addressType.id}>{addressType.name}</option>)
                        }
                    </select>
                </div>
                <div className="col">
                    <label>{address_privated_filter_text}</label>
                    <select className="form-control digits"  onChange={(evt)=>{setSelectedPrivate(evt.target.value=="true"?true:evt.target.value=="false"?false:undefined)}}>
                        <option value={-1}>{"Ninguno"}</option>
                        <option value={true}>{"Private"}</option>
                        <option value={false}>{"Coto"}</option>           
                    </select>
                </div>
            </div>
            <br/>
            <div className="row">
                <div className="col">
                    <label>{user_name_filter_text}</label>
                    <input className="form-control" type="text" autocomplete="off" style={{marginLeft: 10}} value={selectedUsername} onChange={(evt)=>{setSelectedUsername(evt.target.value)}} />
                </div>
            </div>
            <br/>
            <button onClick={uploadStateData} >Load</button>
            <DataTable
                pagination 
                paginationServer
                paginationComponentOptions ={{
                    noRowsPerPage:true
                }}
                paginationTotalRows={totalElements} 
                onChangeRowsPerPage={handlePerRowsChange} 
                onChangePage={handlePageChange}
                striped={true}
                center={true}
                columns={columns}
                data={list}
                />
            
            
            <LoadingSpinner isVisible={loading} />
            <ToastContainer />
        </div>
      
      )
}

export default AddressesView;