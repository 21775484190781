import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import DataTable from 'react-data-table-component'
import useCrud from '../../hooks/use_crud';
import "react-toastify/dist/ReactToastify.css";
import "./service_view.css";
import LoadingSpinner from '../../components/my-components/loading_spinner';
import CreateModal from './create_service_modal';
import IconButton from '../../components/my-components/my_icon_button';
import EditModal from './edit_service_modal';
import GroupBtn from '../../components/buttons/groupBtn';
import { add_text, delete_modal_text, delete_text, edit_text, service_classname_text, service_title_text, view_details_text } from '../../constant';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../components/common/breadcrumb';


const ServiceView = () =>{

    const [loading,setLoading] = useState(false)
    const {list, create, edit, Delete, find, totalElements} = useCrud({url: "services", setLoading: setLoading, firstLoad:false})
   
    const [selectedRow, setSelectedRow] = useState({extrafields: []})

    const [filters, setFilters] = useState({});
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);


    
    const onCreate = async (model) => {
        if(!model.name || model.name === ""){
            toast.error("There are some empty fields. Please, try again")
            return
        }

        

        create(model)
          .catch(error => toast.error("Upps, there are some errors. Please, try again")
      )
      
      setCreateModalIsOpen(false);
    }

    const onEdit = async (orderType) => {

        if(!orderType.name || orderType.name === ""){
                toast.error("There are some empty fields. Please, try again")
                return
            }


            edit(orderType)
          .catch(error => toast.error("Upps, there are some errors. Please, try again")
      )
      
      setEditModalIsOpen(false);
    }

    useEffect(()=>{
        find(filters)
    },[filters])
    
   
    useEffect(()=>{
        let filtersAux = {}
       
        setFilters(filtersAux)
    },[])

    

    const columns = [
      {name: '#',selector: (row) =>row.id,sortable: true,center:true,},
      {name: 'Nombre',selector: (row) =>row.name,sortable: true,center:true,},
      {name: 'Descripción',selector: (row) => row.description,sortable: false,center:true},
      {name: 'Precio',selector: (row) => row.prize,sortable: false,center:true},
      {name: 'Acciones',selector: (row) =>row.actions,sortable: false,center:true},

  ];

    const handleDelete = (id) => {

      if (window.confirm(`${delete_modal_text}${service_classname_text}?`)) {
        Delete(id)
      }
    };

    const handlePageChange = page => {
        find(filters,{size:10,page:page-1});
      };


    const handlePerRowsChange = async (newPerPage, page) => {
        find(filters,{size:newPerPage,page:page});

      };
    

    return (
        <div>
            <Breadcrumb title={service_title_text}/>
            <br/>
            <div style={{display:"flex"}}>
                <div className='btn-right-container'>
                    <Button className='btn btn-primary' color="primary" onClick={()=>{setCreateModalIsOpen(true)}}>{add_text}</Button>
                </div>
            </div>
            <DataTable
                pagination 
                paginationServer
                paginationComponentOptions ={{
                    noRowsPerPage:true
                }}
                paginationTotalRows={totalElements} 
                onChangeRowsPerPage={handlePerRowsChange} 
                onChangePage={handlePageChange}
                striped={true}
                center={true}
                columns={columns}
                data={list.map(row => {
                    return {
                      ...row,
                      actions: <div className='flex'>
                                    <IconButton icon={
                                        <span className='action-box large update-btn'>
                                            <i className='icon'>
                                                <i className='icon-pencil'></i>
                                            </i>
                                        </span>
                                        }
                                        text={edit_text}
                                        onClick={()=>{setSelectedRow(row);setEditModalIsOpen(true); }}
                                        color="warning"
                                    />
                                    <IconButton icon={
                                        <span className='action-box large delete-btn' title='Delete Task'>
                                            <i className='icon'>
                                                <i className='icon-trash'></i>
                                            </i>
                                        </span>
                                        }
                                        text={delete_text}
                                        onClick={()=>{handleDelete(row.id)}}
                                        color="danger"
                                    />
                                </div>
                    }
                  })}
                />
            
            
            <CreateModal 
                isVisible= {createModalIsOpen} 
                onCreate={onCreate} 
                onCancel= {()=>{setCreateModalIsOpen(false)}}
            />

            <EditModal 
                isVisible= {editModalIsOpen} 
                onEdit={onEdit} 
                onCancel= {()=>{setEditModalIsOpen(false)}}
                pages={[]}
                oldRow={selectedRow}
            />
            <LoadingSpinner isVisible={loading} />
            <ToastContainer />
        </div>
      
      )
}

export default ServiceView;