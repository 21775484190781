import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import DataTable from 'react-data-table-component'
import useCrud from '../../hooks/use_crud';
import "react-toastify/dist/ReactToastify.css";
import "./orders_view.css";
import LoadingSpinner from '../../components/my-components/loading_spinner';
import IconButton from '../../components/my-components/my_icon_button';
import {order_breadcrumb_title_text, delete_modal_text, delete_text, dropdown_default_text, filters_text, order_assign_button_text, order_state_filter_text, order_statuses_dropdown_texts, order_title_text, order_type_filter_text, role_classname_text } from '../../constant';
import { orderStatuses } from '../../constant/constants';
import AssignDriverModal from './asign_driver_modal';
import Breadcrumb from '../../components/common/breadcrumb';


const OrdersView = () =>{

    const [loading,setLoading] = useState(false)
    const {list, find,Delete, totalElements, reload} = useCrud({url: "orders", setLoading: setLoading, firstLoad:false})
    const {list: orderTypes} = useCrud({url: "ordertypes", setLoading: setLoading})
    const [assignDriverModalIsOpen,setAssignDriverModalIsOpen] = useState(false);
    const [selectedOrder,setSelectedOrder] = useState({});

    const [selectedOrderType, setSelectedOrderType] = useState({
        id:-1,
        name: "Tipo"
    });

    const [selectedOrderStatus, setSelectedOrderStatus] = useState(null);

    const [filters, setFilters] = useState({});




    useEffect(()=>{
        find(filters)
    },[filters])
    
   
    useEffect(()=>{
        let filtersAux = {}
       
        if(selectedOrderType.id!=-1)
            filtersAux= {
                    ...filtersAux,
                    orderType:{id:selectedOrderType.id}
            }

        if(selectedOrderStatus && selectedOrderStatus!="-1")
                filtersAux= {
                        ...filtersAux,
                        status:selectedOrderStatus
                }

        setFilters(filtersAux)
    },[selectedOrderType,selectedOrderStatus])

   
    

    const columns = [
      {name: '#',selector: (row) =>row.id,sortable: true,center:true,},
      {name: 'Precio',selector: (row) =>row.prize,sortable: true,center:true,},
      {name: 'Requiere Factura',selector: (row) =>row.invoiceRequired?"Sí":"No",minWidth: '200px',sortable: true,center:true,},
      {name: 'Tipo',selector: (row) =>row.orderType.name,sortable: true,center:true,},
      {name: 'Estado',selector: (row) =>order_statuses_dropdown_texts[row.status],sortable: true,center:true,},
      {name: 'Acciones',selector: (row) =>row.actions,sortable: false,center:true},

  ];

    const handleDelete = (id) => {

        if (window.confirm(`${delete_modal_text}${role_classname_text}?`)) {
        Delete(id)
        }
    };

    const handlePageChange = page => {
        find(filters,{size:10,page:page-1});
      };


    const handlePerRowsChange = async (newPerPage, page) => {
        find(filters,{size:newPerPage,page:page});

      };
    
    const onAssign = () => {
        setAssignDriverModalIsOpen(false)
        reload()
    }

    return (
        <div>
            <Breadcrumb parent={order_title_text} title={order_breadcrumb_title_text}/>
            <br/>
            <div className="row">
                <div className="col">
                    <label>{order_type_filter_text}</label>
                    <select className="form-control digits" id="exampleFormControlSelect23" onChange={(evt)=>{setSelectedOrderType({id: parseInt(evt.target.value)})}}>
                        <option value={-1}>{dropdown_default_text}</option>
                        {
                           orderTypes.map(orderType => <option value={orderType.id}>{orderType.name}</option>)
                        }
                    </select>
                </div>
                <div className="col">
                    <label>{order_state_filter_text}</label>
                    <select className="form-control digits" id="exampleFormControlSelect23" onChange={(evt)=>{setSelectedOrderStatus(evt.target.value)}}>
                        <option value={-1}>{dropdown_default_text}</option>
                        {
                           orderStatuses.map(orderStatus => <option value={orderStatus}>{order_statuses_dropdown_texts[orderStatus]}</option>)
                        }
                    </select>
                </div>
            </div>
            <br/>
            <DataTable
                pagination 
                paginationServer
                paginationComponentOptions ={{
                    noRowsPerPage:true
                }}
                paginationTotalRows={totalElements} 
                onChangeRowsPerPage={handlePerRowsChange} 
                onChangePage={handlePageChange}
                striped={true}
                center={true}
                columns={columns}
                data={list.map(row => {
                    return {
                      ...row,
                      actions: <div className='flex'>
                                    {
                                        row.status=="PENDING" && 
                                        <IconButton icon={
                                            <span className='action-box large delete-btn' title='Delete Task'>
                                                <i className='icon'>
                                                    <i className='icon-truck'></i>
                                                </i>
                                            </span>
                                            }
                                            text={order_assign_button_text}
                                            onClick={()=>{
                                                setSelectedOrder(row);
                                                setAssignDriverModalIsOpen(true);
                                            }}
                                            color="primary"
                                        />
                                    }
                                    <IconButton icon={
                                        <span className='action-box large delete-btn' title='Delete Task'>
                                            <i className='icon'>
                                                <i className='icon-trash'></i>
                                            </i>
                                        </span>
                                        }
                                        text={delete_text}
                                        onClick={()=>{handleDelete(row.id)}}
                                        color="danger"
                                    />
                                </div>
                    }
                  })}
                />
            <AssignDriverModal
                isVisible={assignDriverModalIsOpen}
                oldRow={selectedOrder}
                onAssign={onAssign}
                onCancel={()=>{setAssignDriverModalIsOpen(false)}}
            />
            <LoadingSpinner isVisible={loading} />
            <ToastContainer />
        </div>
      
      )
}

export default OrdersView;