
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

 const useCrud = ({
    url,
    setLoading = ()=>{}, 
    filters, 
    pagination = { size:10, page: 0},
    firstLoad = true,
    findUrl ="find",
    findAllUrl ="find-all"
}) =>{


    const [list,setList] = useState([])
    const [totalElements, setTotalElements] = useState(0)
    const [filtersState,setFilters] = useState(filters)
    const [paginationState,setPagination] = useState(pagination)
    const [load,setLoad] = useState(firstLoad)
    const navigate = useNavigate();



    const Delete = async (id)  => {
       
        setLoading(true)

        let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${url}/${id}`,
            {
              method:"DELETE",
              headers:{
                "Content-Type":"application/json",
                "Authorization":`Bearer ${localStorage.getItem("userToken")}`
              }
            }
          )
          
          setLoading(false)

          if(response.ok){
                findPrivate()
                return true;
          }
          throw new Error(`HTTP error! status: ${response.status}`);
        
      
    } 

    const create = async (item) => {

        setLoading(true)

        let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${url}/`,
            {
              method:"POST",
              body:JSON.stringify(item),
              headers:{
                "Content-Type":"application/json",
                "Authorization":`Bearer ${localStorage.getItem("userToken")}`
              }
            }
          )
          
          setLoading(false)

          if(response.ok){
            findPrivate()
            return await response.json();
          }

          throw new Error(`HTTP error! status: ${response.status}`);
    }



    const edit = async (item) => {

      setLoading(true)
      let id = item.id
      item.id=null
      let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${url}/${id}`,
          {
            method:"PUT",
            body:JSON.stringify(item),
            headers:{
              "Content-Type":"application/json",
              "Authorization":`Bearer ${localStorage.getItem("userToken")}`
            }
          }
        )
        
        setLoading(false)

        if(response.ok){
          findPrivate()
          return await response.json();
        }

       

        throw new Error(`HTTP error! status: ${response.status}`);
  }


    const find = (newfilters, newPagination) => {
        setFilters(newfilters)
        if(newPagination){
            setPagination(newPagination)
        }
    }

    const findPrivate = async ()=>{

        setLoading(true)
      try{
        let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${url}/${findUrl}`,
            {
              method:"POST",
              body:JSON.stringify({filters:filtersState,pagination:paginationState}),
              headers:{
                "Content-Type":"application/json",
                "Authorization":`Bearer ${localStorage.getItem("userToken")}`
              },
              credentials:"include"
            }
          ).catch(error => console.log(error))
          
          setLoading(false)

          if(response.ok){
            response.json().then( data => {
              setList(data.data)
              setTotalElements(data.totalElements)
            })
            return true
          }
          
        }
        catch(e){
          setLoading(false)
          //localStorage.setItem("login", false);
          //navigate(`${process.env.PUBLIC_URL}/login?sessionexpired=true`);
          return false
        }

    }

  const findAll = async ()=>{

      setLoading(true)
    try{
      let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${url}/${findAllUrl}`,
          {
            method:"POST",
            body:JSON.stringify({filters:filtersState}),
            headers:{
              "Content-Type":"application/json",
              "Authorization":`Bearer ${localStorage.getItem("userToken")}`
            },
            credentials:"include"
          }
        ).catch(error => console.log(error))
        
        setLoading(false)

        if(response.ok){
          return await response.json()
        }
        
      }
      catch(e){
        setLoading(false)
        //localStorage.setItem("login", false);
        //navigate(`${process.env.PUBLIC_URL}/login?sessionexpired=true`);
        return false
      }

  }



    const findById = async (id)=>{

      setLoading(true)

      let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${url}/${id}`,
          {
            method:"GET",
            headers:{
              "Content-Type":"application/json",
              "Authorization":`Bearer ${localStorage.getItem("userToken")}`
            },
            credentials:"include"
          }
        )
        
        setLoading(false)

        if(response.ok)
          return await response.json()
        
        
        throw new Error(`HTTP error! status: ${response.status}`);


  }


    useEffect(()=>{
        if(load)
            findPrivate()

        setLoad(true)
    },[filtersState,paginationState])


    return {
        list,
        totalElements,
        Delete,
        create,
        edit,
        find,
        findAll,
        reload:findPrivate,
        findById,
    }

}

export default useCrud;